import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Message, Segment } from 'semantic-ui-react';

import EventDetails from '@plone/volto/components/theme/EventDetails/EventDetails';

const View = ({ metadata, properties }) => {
  const content = metadata || properties;
  if (content['@type'] && content['@type'] !== 'Event')
    return (
      <Message warning>
        <Message.Header>
          <FormattedMessage
            id="This block can only be used with Events"
            defaultMessage="This block can only be used with Events"
          />
        </Message.Header>
      </Message>
    );
  return content?.subjects ? (
    <div className="meta-event-wrapper">
      {content.start && content.end && <EventDetails content={content} />}
    </div>
  ) : (
    <div className="meta-event-wrapper">
      <Segment floated="right">
        <div>
          <b>
            <FormattedMessage
              id="metadataevent"
              defaultMessage="Event Metadata"
            />
          </b>
        </div>
        <i>
          <FormattedMessage id="placeholder" defaultMessage="Placeholder" />
        </i>
      </Segment>
    </div>
  );
};

export default View;
