import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { filter } from 'lodash';
import { Button, Container, Message } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import AddOrig from '@plone/volto-original/components/manage/Add/Add';

const Add = (props) => {
  const translationObject = props.location?.state?.translationObject;
  if (translationObject && props.types.length === 0) {
    const origPath = flattenToAppURL(translationObject['@id']);
    const curPath = props.location.pathname.replace('/add', '');
    const body = `Lieber CMS-Support,%0D%0A%0D%0Abitte legen Sie die Übersetzung von ${origPath} hier an: ${curPath}%0D%0A%0D%0ADanke und viele Grüße,%0D%0A`;
    return (
      <Container>
        <Message error>
          <FormattedMessage
            id="You are not allowed to create a translation at this location ({path})."
            defaultMessage="You are not allowed to create a translation at this location ({path})."
            values={{
              path: curPath,
            }}
          />
          <br />
          <br />
          <FormattedMessage
            id="If you still want this translation to be created at this location, please <mail>contact the support</mail>."
            defaultMessage="If you still want this translation to be created at this location, please <mail>contact the support</mail>."
            values={{
              path: '/en',
              mail: (chunks) => (
                <b>
                  <a
                    href={`mailto:cms@uni-koblenz.de?subject=Anfrage: Übersetzung anlegen&body=${body}`}
                  >
                    {chunks}
                  </a>
                </b>
              ),
            }}
          />
        </Message>
        <Button onClick={() => props.history.goBack()}>
          <FormattedMessage id="Back" defaultMessage="Back" />
        </Button>
      </Container>
    );
  }
  return <AddOrig {...props} />;
};

export default compose(
  connect((state) => ({
    types: filter(state.types.types, 'addable'),
  })),
)(Add);
