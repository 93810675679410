import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelResearchProjectsSettings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  labelSelectPerson: {
    id: 'Select person',
    defaultMessage: 'Select person',
  },
  labelSortBy: {
    id: 'Sort by',
    defaultMessage: 'Sort by',
  },
  labelSortReverse: {
    id: 'Reverse sort',
    defaultMessage: 'Reverse sort',
  },
  labelTitle: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  labelStart: {
    id: 'Start date',
    defaultMessage: 'Start date',
  },
  labelEnd: {
    id: 'End date',
    defaultMessage: 'End date',
  },
  labelColumnsCount: {
    id: 'Count of columns',
    defaultMessage: 'Count of columns',
  },
});

const getSortByChoices = (intl) => {
  return [
    ['title', intl.formatMessage(messages.labelTitle)],
    ['start', intl.formatMessage(messages.labelStart)],
    ['end', intl.formatMessage(messages.labelEnd)],
  ];
};

const ResearchProjectsSchema = (intl) => {
  const sortByChoices = getSortByChoices(intl);
  return {
    title: intl.formatMessage(messages.labelResearchProjectsSettings),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['href', 'sort_by', 'sort_reverse', 'grid_columns'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectPerson),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Person'] },
        },
        mode: 'link',
        selectedItemAttrs: [],
      },
      sort_by: {
        title: intl.formatMessage(messages.labelSortBy),
        type: 'string',
        factory: 'Choice',
        choices: sortByChoices,
        isMulti: false,
        default: 'title',
      },
      sort_reverse: {
        title: intl.formatMessage(messages.labelSortReverse),
        type: 'boolean',
        default: false,
      },
      grid_columns: {
        title: intl.formatMessage(messages.labelColumnsCount),
        type: 'integer',
        default: 1,
      },
    },

    required: ['href'],
  };
};

export default ResearchProjectsSchema;
